import React, { useState, useRef, ReactElement } from "react";
import { MAX_WIDTH, ResizeObserver, MOBILE_BREAK } from "./App";

// src, lightbox src (with watermark), title, description
type GalleryImageTitle = string | ReactElement
export type GalleryImage = {
  src: string,
  // Same as src but image has watermark
  lightboxSrc: string,
  title?: GalleryImageTitle,
  description?: string,
}

type GalleryRowProps = {
  items: GalleryImage[];
  imageClicked: (itemIndex: number) => void;
  onLoaded: () => void;
  shown: boolean;
  showDescription?: boolean;
}

export function GalleryRow(props: GalleryRowProps) {
  const images = props.items;
  const refs: React.MutableRefObject<HTMLImageElement | null>[] = [];
  for (let i = 0; i < images.length; i++) {
    refs[i] = useRef<HTMLImageElement | null>(null);
  }

  const [imageWidths, setImageWidths] = useState<number[]>([]);
  const gridPadding = 20 * (images.length + 1); // space-evenly, so pad between every image and on both sides

  const onResize = () => {
    if (!refs.every((ref) => ref.current?.complete)) {
      return;
    }

    let windowWidth = document.documentElement.clientWidth;

    const pagePadding = 40;
    if (windowWidth > MAX_WIDTH) {
      windowWidth = MAX_WIDTH;
    }
    windowWidth -= 2 * pagePadding;
    windowWidth -= gridPadding;

    let windowHeight = document.documentElement.clientHeight - 2 * pagePadding;

    const newImageWidths = Array(images.length);

    // Step 1: resize all images to match height of first image
    let totalWidth = 0;
    let firstHeight = refs[0].current?.naturalHeight;
    let imageHeight = refs[0].current?.naturalHeight || 1;

    refs.forEach((ref, i: number) => {
      const image = ref.current;
      if (image == null || firstHeight == null) {
        return;
      }

      let newWidth = (firstHeight * image.naturalWidth) / image.naturalHeight;
      newImageWidths[i] = newWidth;
      totalWidth += newWidth;
    });

    // Step 2: resize all images to fit in the window
    const resizeRatio = Math.min(windowWidth / totalWidth, windowHeight / imageHeight);
    console.log(windowWidth / totalWidth, windowHeight / imageHeight, windowHeight, imageHeight);
    refs.forEach((_ref, i: number) => {
      newImageWidths[i] = newImageWidths[i] * resizeRatio;

      // cap width of single image so it fits on screen
      if (refs.length === 1) {
        const image = refs[i].current;
        if (image == null) { return }
        const maxWidth = windowHeight * image.naturalWidth / image.naturalHeight;
        newImageWidths[i] = Math.min(newImageWidths[i], maxWidth);
      }
    });

    setImageWidths(newImageWidths);
  };

  const onImageLoad = () => {
    if (refs.every((ref) => ref.current?.complete)) {
      props.onLoaded();
    }

    onResize();
  }

  React.useLayoutEffect(() => {
    try {
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(document.documentElement);
      return () => resizeObserver.disconnect();
    } catch {
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }
  }, []);

  const showDescription = props.showDescription !== false;

  return (
    <div className={`gallery-row ${props.shown ? '' : 'hidden'}`}>
      {images.map((item: GalleryImage, itemIndex: number) => {
        const imageLoaded = refs[itemIndex].current?.complete
        return (
          <div key={itemIndex}>
            <picture>
              <source
                srcSet={item.lightboxSrc}
                media={`(max-width: ${MOBILE_BREAK}px)`} />
              <img
                key={itemIndex}
                src={item.src}
                width={imageWidths[itemIndex] || 0}
                ref={refs[itemIndex]}
                onLoad={onImageLoad}
                onMouseDown={() => props.imageClicked(itemIndex)} />
            </picture>
            {imageLoaded && showDescription && (
              <div className="gallery-label">
                <div className="gallery-item-title">
                  {item.title}
                </div>
                <div className="gallery-item-desc">
                  {item.description}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
